import { DateHelper, NodeReferenceHelper, vueLazySetMapValue, VueLazyWrapper } from "@/core/helpers"
import { AnySplitterStrategy } from "@/core/helpers/NodesetUpdater/AnySplitterStrategy"
import { NodeGroup, ProvisioningState } from "@/core/interfaces"
import ApiService from "@/core/services/api.service"
import { jsonRpcService } from "@/core/services/json-rpc.service"
import { LayoutValidity } from "@/core/validation"
import { NodeReference, SiteNodes } from "@/Simulation"
import { NodeComputationHelper } from "@/Simulation/helpers"
import { ItemParameter } from "@/Simulation/siteParameter/nodeParameter"
import { Models, Services } from "@ekko/predict-client-api"
import { ConnectionType, NodeType } from "predict-performance-calculation"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import {
    ITNodeType,
    MeterNodeType,
    ModelType,
    MultiSelectMode,
    SplitterCombinerType,
} from "../core/enums"
import { InitialiseInformation } from "./InitialiseInformation"
import { InitialiseState } from "./InitialiseState"
import { Preload } from "../core/helpers/Preload"
import { SimulationViewState } from "./SimulationViewState"

/**
 * Set to true to debug initialise progress
 */
const debugInitialise = false

export type SiteConfigurationScenario = { model: Models.SiteConfigurationModel, dateFrom: string, dateTo: string }

/**
 *
 */
export type AnyNodeModel = Models.ItemModel | Models.CombinerModel |
    Models.ITNodeModel | Models.OtherNodeModel | Models.SplitterModel |
    Models.CoolingMeterPointModel | Models.PowerMeterPointModel

/**
 * This acts as an assertion that the external type does comply with
 * NodeReference
 */
export type NRef<T extends AnyNodeModel> = T & NodeReference

/**
 *
 */
export type AnyNode = NRef<AnyNodeModel>

export type AnyNodeService = Services.ItemService | Services.CombinerService | Services.ITNodeService | Services.OtherNodeService |
    Services.SplitterService | Services.CoolingMeterPointService | Services.PowerMeterPointService

export interface SiteHeader {
    /**
     *
     */
    configurationName: string
    /**
     *
     */
    configurationsCount: number
    /**
     *
     */
    name: string,
    /**
     *
     */
    date: string
}
export interface Connection {
    /**
     *
     */
    from: string

    /**
     *
     */
    to: string

    /**
     *
     */
    type: ConnectionType
}

/**
 *
 */
export class SiteViewState extends SimulationViewState {
    /**
     * This is the last implementation in the chain
     */
    activeSiteConfiguration: Models.SiteConfigurationModel | null = null

    /**
     *
     */
    brokenNodes: string[] = []

    /**
     *
     */
    cachedDeviceModes = false

    /**
     *
     */
    canvasEvent: { event: string, params?: any } | null = null

    /**
     *
     */
    deviceModels: Models.DeviceModelModel[] = []

    /**
     *
     */
    filter: { [section: string]: string[] } = {}

    /**
     *
     */
    groups: NodeGroup[] = []

    /**
     *
     */
    initialiseInformation: InitialiseInformation | null = null

    /**
     *
     */
    isDraft: boolean | null = null

    /**
     *
     */
    layoutActionRev = 0

    /**
     *
     */
    layoutActionsInProgress = new Set<string>()

    /**
     *
     */
    layoutValidity = new LayoutValidity(null)

    /**
     *
     */
    lazyItemDeviceModes = new Map<string, VueLazyWrapper<Models.DeviceModeModel>>()

    /**
     *
     */
    lazyItemProvisioning = new Map<string, VueLazyWrapper<ProvisioningState>>()

    /**
     *
     */
    lazyModeDeviceModels = new Map<string, VueLazyWrapper<Models.DeviceModelModel>>()

    /**
     *
     */
    lazySiteConfigurationIsDraft = new Map<string, VueLazyWrapper<boolean>>()

    /**
     *
     */
    lazySiteConfigurationIsPreviousDraft = new Map<string, VueLazyWrapper<boolean>>()

    /**
     *
     */
    lazySplitterStrategies = new Map<string, VueLazyWrapper<AnySplitterStrategy>>()

    /**
     *
     */
    mode: MultiSelectMode | null = null

    /**
     *
     */
    multiSelectedNodes: string[] = []

    /**
     *
     */
    nodes: Array<AnyNode> | null = null

    /**
     *
     */
    screenshotLastChecked: Date | null = null

    /**
     *
     */
    selectedNode: string | null = null

    /**
     *
     */
    selectedNodeObject: AnyNode | null = null

    /**
     *
     */
    sidePanelOpen = true

    /**
     * This MUST be set before using the store.
     */
    site: Models.SiteModel = null as unknown as Models.SiteModel

    /**
     *
     */
    siteConfiguration: Models.SiteConfigurationModel | null = null

    /**
     *
     */
    siteConfigurations: Models.SiteConfigurationModel[] | null = null

    /**
     *
     */
    siteConfigurationSearch = ""

    /**
     *
     */
    siteLayoutScreenshot: Models.SiteLayoutScreenshotModel | null = null

    /**
     *
     */
    visibleNodes: Array<AnyNode> | null = null
}

export const SiteViewStateGetters: GetterTree<SiteViewState, any> = {
    /**
     * @param state
     * @param getters
     */
    activeSiteConfigurations(state, getters): Models.SiteConfigurationModel[] | null {
        if (state.siteConfigurations) {
            return state.siteConfigurations.filter(
                configuration => getters.siteConfigurationIsDraft(configuration) === false
            )
        } else {
            return null
        }
    },
    /**
     * @param state
     */
    connections(state): Connection[] | null {
        if (!state.nodes) return null
        const connections: Connection[] = []
        /**
         *
         * @param node
         * @param type
         * @param relationship
         */
        function addConnection(node: AnyNode, type: ConnectionType, relationship?: { type: string, id: string }[] | { type: string, id: string } | null) {
            if (relationship) {
                let toNodeRefs: { type: string, id: string }[]
                if (Array.isArray(relationship)) {
                    toNodeRefs = relationship
                } else {
                    toNodeRefs = [relationship]
                }
                connections.push(...toNodeRefs.map(by => ({
                    from: node.type + "/" + node.id,
                    to: by.type + "/" + by.id,
                    type,
                } as Connection)))
            }
        }

        for (const node of state.nodes) {
            addConnection(
                node,
                ConnectionType.COOLING,
                node.data.relationships?.cooledBy?.data
            )
            addConnection(
                node,
                ConnectionType.POWER,
                node.data.relationships?.poweredBy?.data
            )
            addConnection(
                node,
                ConnectionType.OVERHEAD,
                node.data.relationships?.otherSupplyBy?.data
            )
        }
        return connections
    },
    /**
     * @param state
     */
    getSiteLayoutScreenshot(state): Models.SiteLayoutScreenshotModel | null {
        return state.siteLayoutScreenshot
    },
    /**
     * @param state
     */
    getSiteLayoutScreenshotTime(state): string | null {
        return state.siteLayoutScreenshot?.getUpdated() || null
    },
    /**
     *
     * @param state
     * @returns
     */
    viewMargin: (state) => (isSidePanelLeft) => {
        return {
            left: isSidePanelLeft ? 310 : 50,
            top: 50,
            right: state.sidePanelOpen ? 260 : 0,
            bottom: 100,
        }
    },
    /**
     * @param state
     */
    getScreenshotLastChecked(state): Date | null {
        return state.screenshotLastChecked
    },

    /**
     * @param state
     */
    headerInformation(state): SiteHeader | null {
        if (state.site && state.siteConfiguration) {
            return {
                configurationName: state.siteConfiguration.getName()!,
                configurationsCount: state.siteConfigurations ? state.siteConfigurations.length : 0,
                date: state.siteConfiguration.getEffectiveDate() ?
                    DateHelper.headerDateFormat(state.siteConfiguration.getEffectiveDate()!) :
                    "",
                name: state.site.getName() as string,
            }
        } else {
            return null
        }
    },

    /**
     * @param state
     */
    currentProvisioningFor(state) {
        return (nodeRef: string) => {
            if (!state.nodes) return null
            const item = state.nodes.find(n => NodeReferenceHelper.getNodeReference(n) == nodeRef) as Models.ItemModel | null
            if (!item) return null
            return vueLazySetMapValue(
                state.lazyItemProvisioning,
                nodeRef,
                async () => {
                    const now = new Date()
                    let latestDate: Date | undefined
                    let latestEvent: Models.ProvisioningEventModel | undefined
                    for await (const event of item.getProvisioningEvents()) {
                        const eventDate = new Date(event.getDate()!)
                        if (eventDate < now && (!latestDate || latestDate <= eventDate)) {
                            latestDate = eventDate
                            latestEvent = event
                        }
                    }
                    const itemParameter = new ItemParameter()
                    if (latestEvent) {
                        const resilience = await itemParameter.getResilience(item)
                        const deviceMode = await item.getDeviceMode()
                        const calculatedCapacity =
                            NodeComputationHelper.getComputedProvisionedCapacity(
                                latestEvent.getCount() ?? 1, resilience,
                                deviceMode?.getCapacity() ?? 1)

                        return {
                            count: latestEvent.getCount() ?? 1,
                            designCapacity: calculatedCapacity,
                            designCount: latestEvent.getDesignCount() ?? undefined,
                        }
                    } else {
                        return {
                            count: 0,
                        }
                    }
                }
            )
        }
    },

    /**
     *
     * @param state
     * @returns
     */
    siteConfigurationIsDraft(state) {
        /**
         * @param siteConfiguration
         */
        return (siteConfiguration: Models.SiteConfigurationModel | null) => {
            if (!siteConfiguration) return null
            return vueLazySetMapValue(
                state.lazySiteConfigurationIsDraft,
                siteConfiguration.id,
                async () => {
                    const follows = await siteConfiguration.getFollows()
                    if (follows !== null) {
                        return false
                    } else {
                        const starts = await siteConfiguration.getStartsSite()
                        return starts === null
                    }
                }
            )
        }
    },

    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    siteConfigurationIsPreviousDraft(state, getters) {
        /**
         * @param siteConfiguration
         */
        return (siteConfiguration: Models.SiteConfigurationModel | null) => {
            if (!siteConfiguration) return null
            return getters.siteConfigurationIsDraft(siteConfiguration) && vueLazySetMapValue(
                state.lazySiteConfigurationIsPreviousDraft,
                siteConfiguration.id,
                async () => {
                    const implementation = await siteConfiguration.getWillFollow()
                    return implementation && await implementation.getFollowedBy() !== null
                }
            )
        }
    },

    /**
     * @param state
     * @param getters
     */
    usableTypesForFilter(state, getters) {
        return (name) => {
            if (!state.nodes) return null
            const types: string[] = []
            switch (name) {
                case "Cooling":
                    types.push(...getters.usedDeviceTypes)
                    break
                case "Power":
                    if (state.nodes.some(n => n.type == NodeType.ITNode)) {
                        types.push(ITNodeType.IT)
                    }
                    if (state.nodes.some(n => n.type == NodeType.PowerMeterPoint || n.type == NodeType.CoolingMeterPoint)) {
                        types.push(MeterNodeType.METER)
                    }
                    {
                        const otherNodes = state.nodes.filter(n => n.type == NodeType.OtherNode) as Models.OtherNodeModel[]
                        const seenType = new Map<string, boolean>()
                        for (const otherNode of otherNodes) {
                            seenType.set(otherNode.getSupplyType()!, true)
                        }
                        types.push(...seenType.keys())
                    }
                    types.push(...getters.usedDeviceTypes)
                    break
                case "SplitterCombiner":
                    if (state.nodes.some(n => n.type == NodeType.Combiner)) {
                        types.push(SplitterCombinerType.COMBINER)
                    }
                    {
                        const splitters = state.nodes.filter(n => n.type == NodeType.Splitter) as Models.SplitterModel[]
                        const splitterStrategyType = {
                            [ModelType.CHEAP_ONE_COST_STRATEGY]: SplitterCombinerType.ECONOMIZER,
                            [ModelType.SHARE_COST_STRATEGY]: SplitterCombinerType.SHARED,
                            [ModelType.PRIORITY_COST_STRATEGY]: SplitterCombinerType.STAGED,
                            [ModelType.ENVIRONMENT_COST_STRATEGY]: SplitterCombinerType.TEMPERATURE,
                        }
                        const seenStrategy = new Map<string, boolean>()
                        for (const splitter of splitters) {
                            const dcs = vueLazySetMapValue(
                                state.lazySplitterStrategies,
                                splitter.id,
                                () => splitter.getSplitterDynamicCostStrategy()
                            )
                            if (dcs) {
                                seenStrategy.set(dcs.type, true)
                            }
                        }
                        for (const strategy of seenStrategy.keys()) {
                            if (splitterStrategyType[strategy]) {
                                types.push(splitterStrategyType[strategy])
                            }
                        }
                    }
                    break
                default:
                // No-op
            }
            return types
        }
    },

    /**
     * @param state
     */
    usedDeviceTypes(state) {
        if (!state.nodes) return null
        const items = state.nodes.filter(n => n.type == NodeType.Item) as Models.ItemModel[]
        const seenType = new Map<string, boolean>()
        for (const item of items) {
            const mode = vueLazySetMapValue(
                state.lazyItemDeviceModes,
                item.id,
                () => item.getDeviceMode()
            )
            if (!mode) continue
            const model = vueLazySetMapValue(
                state.lazyModeDeviceModels,
                mode.id,
                () => mode.getDeviceModel()
            )
            if (!model) continue
            seenType.set(model.getDeviceModelType()!, true)
        }
        return [...seenType.keys()]
    },
}

export const SiteViewStateMutations: MutationTree<SiteViewState> = {
    /**
     *
     * @param state
     * @param states
     */
    addInitialiseStates(state, states: Record<string, InitialiseState>) {
        if (state.initialiseInformation) {
            state.initialiseInformation.states = {
                ...state.initialiseInformation.states,
                ...states,
            }
        } else {
            state.initialiseInformation = new InitialiseInformation(states)
            state.initialiseInformation.init()
        }
    },
    /**
     *
     * @param state
     */
    clearInitialiseStates(state) {
        state.initialiseInformation = null
    },
    /**
     *
     * @param state
     * @param node
     */
    clearProvisioningCacheFor(state, node: AnyNode) {
        state.lazyItemProvisioning.delete(NodeReferenceHelper.getNodeReference(node))
    },
    /**
     *
     * @param state
     * @returns
     */
    incrementLayoutActionRev(state) {
        state.layoutActionRev++
    },

    /**
     * @param state
     * @param siteConfiguration
     */
    setActiveSiteConfiguration(state, siteConfiguration: Models.SiteConfigurationModel) {
        state.activeSiteConfiguration = siteConfiguration
    },

    /**
     *
     * @param state
     * @param isCached
     */
    setCachedDeviceModes(state, isCached: boolean) {
        state.cachedDeviceModes = isCached
    },

    /**
     * @param state
     * @param canvasEvent
     */
    setCanvasEvent(state, canvasEvent: SiteViewState["canvasEvent"]) {
        state.canvasEvent = canvasEvent
    },

    /**
     * @param state
     * @param mode
     */
    setCanvasMode(state, mode: SiteViewState["mode"]) {
        state.mode = mode
    },

    /**
     * @param state
     * @param cloneNodes
     */
    setClonedNodes(state, cloneNodes: AnyNode[]) {
        state.nodes = [...state.nodes || [], ...cloneNodes]
        state.layoutValidity.setNodes(state.nodes)
    },

    /**
     *
     * @param state
     * @param deviceModels
     */
    setDeviceModels(state, deviceModels) {
        state.deviceModels = deviceModels
    },

    /**
     * @param state
     * @param filter
     */
    setFilter(state, filter: { [section: string]: string[] }) {
        state.filter = {
            ...state.filter,
            ...filter,
        }
        state.filterCalculation = null
    },

    /**
     *
     * @param state
     * @param groups
     */
    setGroups(state, groups: NodeGroup[]) {
        state.groups = groups
    },

    /**
     * @param state
     * @param isDraft
     */
    setIsDraft(state, isDraft: boolean | null) {
        state.isDraft = isDraft
    },

    /**
     * @param state
     * @param layoutValidity
     */
    setLayoutValidity(state, layoutValidity: LayoutValidity) {
        state.layoutValidity = layoutValidity
    },

    /**
     * @param state
     * @param selection
     */
    setMultiSelectedNodes(state, selection: string[]) {
        state.multiSelectedNodes = selection
    },

    /**
     * @param state
     * @param nodes
     */
    async setNodes(state, nodes: AnyNode[]) {
        state.nodes = nodes
        state.lazyItemDeviceModes = new Map()
        state.lazyItemProvisioning = new Map()
        state.lazySplitterStrategies = new Map()
        state.visibleNodes = nodes
        state.layoutValidity.setNodes(nodes)
    },

    /**
     * @param state
     * @param siteLayoutcreenshot
     */
    setScreenshotForSite(state, siteLayoutcreenshot: Models.SiteLayoutScreenshotModel) {
        state.siteLayoutScreenshot = siteLayoutcreenshot
    },

    /**
     * @param state
     * @param siteLayoutcreenshot
     */
    setScreenshotLastChecked(state, date: Date) {
        state.screenshotLastChecked = date
    },

    /**
     * @param state
     * @param selectedNode
     */
    setSelectedNode(state, selectedNode: string | null) {
        state.selectedNode = selectedNode
        state.selectedNodeObject = null
    },

    /**
     * @param state
     * @param selectedNodeObject
     */
    setSelectedNodeObject(state, selectedNodeObject: AnyNode | null) {

        state.selectedNodeObject = selectedNodeObject
    },
    /**
     *
     * @param state
     * @param isOpen
     */
    setSidePanelOpen(state, isOpen: boolean) {
        state.sidePanelOpen = isOpen
    },

    /**
     * @param state
     * @param site
     */
    setSite(state, site: Models.SiteModel) {
        state.site = site
        state.layoutValidity = new LayoutValidity(site)
    },

    /**
     * @param state
     * @param siteConfiguration
     */
    setSiteConfiguration(state, siteConfiguration: Models.SiteConfigurationModel) {
        state.siteConfiguration = siteConfiguration
    },

    /**
     * @param siteConfigurations
     */
    setSiteConfigurations(state, siteConfigurations: Models.SiteConfigurationModel[]) {
        state.siteConfigurations = siteConfigurations
        state.lazySiteConfigurationIsDraft = new Map()
    },

    /**
     *
     * @param state
     * @param actionKey
     * @returns
     */
    startLayoutActionProgress(state, actionKey: string) {
        state.layoutActionsInProgress.add(actionKey)
    },

    /**
     *
     * @param state
     * @param actionKey
     */
    stopLayoutActionProgress(state, actionKey: string) {
        state.layoutActionsInProgress.delete(actionKey)
    },

    /**
     *
     * @param state
     * @param initialiseState
     */
    updateInitialiseState(state, initialiseState: InitialiseState) {
        state.initialiseInformation?.update(initialiseState)
    },

    /**
     * @param state
     * @param siteConfiguration
     */
    updateSiteConfiguration(state, siteConfiguration: Models.SiteConfigurationModel): void {
        if (state.siteConfigurations) {
            state.siteConfigurations = state.siteConfigurations.map(sc => {
                if ((sc as any).id === (siteConfiguration as any).id) {
                    return siteConfiguration
                }
                return sc
            })
        }
        state.lazySiteConfigurationIsDraft.delete(siteConfiguration.id)
    },
}

export const SiteViewStateActions: ActionTree<SiteViewState, any> = {
    /**
     *
     * @param actionState
     */
    async cacheDeviceModes({ commit, dispatch, state }) {
        if (!state.cachedDeviceModes) {
            await dispatch("fetchDeviceModels")
            const service = await ApiService.getDeviceModeService()

            for await (const entityPage of service.getAllDeviceModesPaginated()) {
                // Noop
            }
            commit("setCachedDeviceModes", true)
        }
    },

    /**
     * @param actionState
     */
    checkNodesValidity({ state }) {
        return state.layoutValidity.checkNodes()
    },

    /**
     * @param actionState
     */
    clearCanvasEvent({ commit }) {
        commit("setCanvasEvent", null)
    },

    /**
     *
     * @param actionState
     */
    closeSidePanel({ commit, dispatch, getters }) {
        commit("setSidePanelOpen", false)
        return dispatch("updateCanvasViewMargin")
    },

    /**
     *
     * @param actionState
     */
    async fetchDeviceModels({ commit }) {
        const deviceModelService = await ApiService.getDeviceModelService()

        const deviceModelFound: Models.DeviceModelModel[] = []
        for await (const deviceModelPage of deviceModelService.getAllDeviceModelsPaginated()) {
            deviceModelFound.push(...deviceModelPage)
        }
        commit("setDeviceModels", deviceModelFound)

    },

    /**
     *
     * @param actionState
     */
    async fetchNodeGroups({ commit, state }) {

        const siteConfiguration = state.siteConfiguration
        if (!siteConfiguration) {
            throw new Error(`Cannot fetch groups without a site configuration`)
        }
        const foundGroups: NodeGroup[] = []

        for await (const nodeGroupModel of siteConfiguration.getNodeGroups()) {
            foundGroups.push({
                colour: nodeGroupModel.getColour(),
                name: nodeGroupModel.getName(),
                nodes: nodeGroupModel.getNodes()
            } as NodeGroup)
        }

        commit("setGroups", foundGroups)

    },

    /**
     *
     * @param siteId
     */
    async fetchScreenshotForSite({ commit, state }) {
        if (state.site) {
            const siteLayoutScreenshot = await state.site.getSiteLayoutScreenshot()
            if (siteLayoutScreenshot) {
                commit("setScreenshotLastChecked", new Date(siteLayoutScreenshot.getUpdated() as string))
                commit("setScreenshotForSite", siteLayoutScreenshot)
            }
        }
    },

    /**
     * @param actionState
     */
    async fetchSiteConfigurations({ state }) {
        const configurations = <Models.SiteConfigurationModel[]>[]

        if (state.site) {
            for await (const configuration of state.site.getSiteConfigurations()) {
                configurations.push(configuration)
            }
        }

        return configurations
    },

    /**
     * This will run each loader in order, updating the initialise state as it
     * goes.
     *
     * @param param0
     * @param loaders
     */
    async initialise({ commit }, loaders: Record<string, () => (Promise<any> | any)>) {
        let initialiseWarnHandler: ReturnType<typeof globalThis.setTimeout> | undefined
        if(debugInitialise) {
            console.log(`Initialise: adding loaders ${Object.keys(loaders)}`)
            initialiseWarnHandler = setTimeout(() => {console.warn(
                    "Initialise handler took too long, one or more promises may be failed",
                    10_000)})
        }
        try {
            commit("addInitialiseStates", Object.fromEntries(
                Object.keys(loaders).map(name => [name, { loaded: false }])
            ))
            for (const [name, loader] of Object.entries(loaders)) {
                const start = new Date()
                if(debugInitialise) {
                    console.log(`Initialise: Starting ${name}`)
                }
                await loader()
                if(debugInitialise) {
                    const finish = new Date()
                    console.log(`Initialise: ${name}: complete after ${finish.valueOf() - start.valueOf()}ms`)
                }
                commit("updateInitialiseState", { name, loaded: true })
            }
            if(debugInitialise) {
                console.log("Initialise: all loaders complete")
            }
        } finally {
            if(initialiseWarnHandler) {
                clearTimeout(initialiseWarnHandler)
            }
        }
    },

    /**
     *
     * @param actionState
     */
    async loadNodes({ dispatch }) {
        return dispatch("loadNodesOnly")
        // Previously this tried to cache all device modes, which was not
        // helpful.
    },

    /**
     *
     * @param actionState
     */
    async loadNodesOnly({ dispatch, state }) {
        const siteConfiguration = state.siteConfiguration
        if (!siteConfiguration) {
            throw new Error("A site configuration must be selected first")
        }
        const nodes = await SiteNodes.forSiteConfig(siteConfiguration)
        await dispatch("updateNodes", nodes)
    },

    /**
     *
     * @param actionState
     */
    openSidePanel({ commit, dispatch, getters }) {
        commit("setSidePanelOpen", true)
        return dispatch("updateCanvasViewMargin")
    },

    /**
     *
     * @param state
     * @param actions
     * @returns
     */
    async performLayoutAction({ dispatch }, actions: Record<string, () => Promise<any>>) {
        const keys = Object.keys(actions)
        if(keys.length != 1) {
            throw new Error("No action requested")
        }
        return (await dispatch("performLayoutActions", actions))[keys[0]]
    },

    /**
     *
     * @param state
     * @param actions
     * @returns
     */
    async performLayoutActions({ commit, state }, actions: Record<string, () => Promise<any>>) {
        const results: Record<string, any> = {}
        for(const [action, f] of Object.entries(actions)) {
            const actionKey = `${state.layoutActionRev}. ${action}`
            commit("incrementLayoutActionRev")
            commit("startLayoutActionProgress", actionKey)
            try {
                results[action] = await f()
            } finally {
                commit("stopLayoutActionProgress", actionKey)
            }
        }
        return results
    },

    /**
     *
     * @param param0
     */
    async recheckOwnLayoutValidity({ dispatch, state }) {
        await state.layoutValidity.checkSite()
        await dispatch("checkNodesValidity")
    },

    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async removeSiteConfiguration({ dispatch, state }, siteConfiguration: Models.SiteConfigurationModel) {
        const siteConfigurationService =
            await ApiService.getSiteConfigurationService()

        // Find and remove any parent draft relationships, just in-memory
        const willFollow = await siteConfiguration.getWillFollow()
        willFollow?.removeDrafts([siteConfiguration])
        const site = state.site
        site.removeSiteConfigurations([siteConfiguration])

        await siteConfigurationService.removeSiteConfiguration(siteConfiguration)

        if (site) {
            let siteConfiguration: Models.SiteConfigurationModel | null = null
            const configurations = <Models.SiteConfigurationModel[]>[]
            // Prefetch all siteConfigurations from site
            // Can be deleted more than one record from DB (Cascade delete in SiteConfigurationHandler)
            for await (const configuration of site.getSiteConfigurations()) {
                configurations.push(configuration)
                if (await configuration.getFollows() && !await configuration.getFollowedBy()) {
                    siteConfiguration = configuration
                }
            }

            // Set First Configuration as default configuration in view
            await dispatch("selectSiteConfiguration", siteConfiguration || await site.getFirstConfiguration())
            await dispatch("updateSiteConfigurations", configurations)
        }
    },

    /**
     * @param param0
     */
    resetInitialiseState({ commit }) {
        if(debugInitialise) {
            console.log("Initialise: Resetting state")
        }
        commit("clearInitialiseStates")
    },

    /**
     * Save ScreenshotForSite and after that fetch
     *
     * @param actionState
     * @param siteData
     */
    async saveScreenshotForSite({ dispatch },
        { siteId, encodedData }: { siteId: string, encodedData: string }
    ) {
        const res =
            await jsonRpcService.saveScreenshotForSite(siteId, encodedData)
        if (res) {
            dispatch("fetchScreenshotForSite", siteId)
        }
    },

    /**
     *
     * @param actionState
     * @param nodes
     */
    async selectNodes({ commit }, nodes: { type: string, id: string }[]) {
        commit("setMultiSelectedNodes", nodes.map(n => n.type + "/" + n.id))
    },

    /**
     *
     * @param actionState
     * @param site
     */
    async selectSite({ commit, dispatch }, site: Models.SiteModel) {
        let siteConfiguration: Models.SiteConfigurationModel | null = null
        const configurations = <Models.SiteConfigurationModel[]>[]

        // This finds the active site configuration (if possible).
        for await (const configuration of site.getSiteConfigurations()) {
            configurations.push(configuration)
            if (await configuration.getFollows() && !await configuration.getFollowedBy()) {
                siteConfiguration = configuration
            }
        }

        commit("setSite", site)
        await dispatch("selectSiteConfiguration", siteConfiguration || await site.getFirstConfiguration())
        await dispatch("updateSiteConfigurations", configurations)
    },

    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async selectSiteConfiguration({ commit }, siteConfiguration: Models.SiteConfigurationModel | null) {
        commit("setIsDraft", null)
        commit("setSiteConfiguration", siteConfiguration)
        await Preload.deviceModes(siteConfiguration).catch(e => console.warn(e))
        if (siteConfiguration) {
            if (await siteConfiguration.getFollows() || await siteConfiguration.getStartsSite()) {
                commit("setIsDraft", false)
            } else {
                commit("setIsDraft", true)
            }
        }
    },

    /**
     * @param actionState
     * @param eventName
     */
    sendCanvasEvent({ dispatch }, eventName: string) {
        return dispatch("sendCanvasEventFull", { event: eventName })
    },

    /**
     * @param actionState
     * @param canvasEvent
     */
    sendCanvasEventFull({ commit }, canvasEvent: SiteViewState["canvasEvent"]) {
        commit("setCanvasEvent", canvasEvent)
    },

    /**
     *
     * @param actionState
     * @param mode
     */
    updateCanvasMode({ commit }, mode: SiteViewState["mode"]) {
        commit("setCanvasMode", mode)
    },

    /**
     *
     * @param actionState
     * @returns
     */
    updateCanvasViewMargin({ dispatch, getters }, isSidePanelLeft: boolean) {
        return dispatch("sendCanvasEventFull", {
            event: "viewMarginChanged",
            params: getters.viewMargin(isSidePanelLeft),
        })
    },

    /**
     * @param actionState
     * @param filter
     */
    updateFilter({ commit }, filter: { [section: string]: string[] }) {
        commit("setFilter", filter)
    },

    /**
     *
     * @param actionState
     * @param nodes
     */
    updateNodes({ commit, dispatch }, nodes: AnyNode[]) {
        commit("setNodes", nodes)
        return dispatch("checkNodesValidity")
    },

    /**
     *
     * @param date
     */
    async updateScreenshotLastChecked({ commit }, date: Date) {
        commit("setScreenshotLastChecked", date)
    },

    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async updateSiteConfiguration({ commit, dispatch }, siteConfiguration: Models.SiteConfigurationModel | null) {
        commit("updateSiteConfiguration", siteConfiguration)
        await dispatch("selectSiteConfiguration", siteConfiguration)
    },

    /**
     *
     * @param actionState
     * @param configurations
     */
    async updateSiteConfigurations({ commit, state }, configurations: Models.SiteConfigurationModel[]) {
        commit("setSiteConfigurations", configurations)

        // "Active" is just the last one in the chain.
        for (const configuration of configurations.slice().reverse()) {
            if (await configuration.getFollowedBy()) {
                continue
            }

            if (await configuration.getFollows() || await configuration.getStartsSite()) {
                commit("setActiveSiteConfiguration", configuration)
                if (!configurations.some(c => c.id == state.siteConfiguration?.id)) {
                    commit("setSiteConfiguration", configuration)
                    await Preload.deviceModes(configuration).catch(
                        e => console.warn(e))
                }
                return
            }
        }

        console.error("No valid active site configuration found")
        commit("setActiveSiteConfiguration", null)
    },
}