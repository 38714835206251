import { ClimateProfileData } from "@/Simulation/interfaces/ClimateProfileData"
import { Models } from "@ekko/predict-client-api"
import { cacheWrap } from "@ekko/predict-utilities"
import { AccessLevel } from "../enums"
import { Endpoint, UrlConfig } from "../helpers"
import { SensorInformation, WeatherReading } from "../interfaces"
import { ClimateSummary } from "../interfaces/ClimateSummary"
import { BaseHttpService } from "./BaseHttpService"

/**
 * Http service for custom routes
 */
class HttpService extends BaseHttpService {
    protected endpoint: Endpoint = "api"

    unauthenticate() {
        const token = sessionStorage.getItem(BaseHttpService.sessionName)
        const refreshToken = sessionStorage.getItem(BaseHttpService.refreshToken)
        if(token) {
            sessionStorage.removeItem(BaseHttpService.sessionName)
        }
        if(refreshToken) {
            sessionStorage.removeItem(BaseHttpService.refreshToken)
        }
        location.href = process.env.VUE_APP_AUTHENTICATION_URL!
    }

    /**
     * Returns the owner reference info for the active user
     */
    async ownOwnerReference() {
        return this.authenticatedGetJson<{ data: { id: string, organisation: string } }>(`/ownOwnerReference`)
    }

    /**
     * Uploads climate profile file
     * @param file
     * @param accessLevel This argument is currently ignored
     * @param [origin]
     */
    async uploadClimateProfile(
        file: File,
        accessLevel: AccessLevel | null,
        origin = "energyplus"
    ): Promise<Pick<Models.ClimateProfileReferenceModel, "id" | "type">> {
        const formData = new FormData()
        formData.append('origin', origin)
        if(accessLevel) {
            formData.append('accessLevel', accessLevel)
        }
        formData.append('file', file)

        const response = await this.authenticatedPOST(`/file/climate`, formData)
        const result = await response.json()

        return result.data

    }

    /**
     * Gets climate profile by Id
     * @param id
     */
    getClimateProfileSummary(id: string) {
        return this.authenticatedGetJson<ClimateSummary>(`/computed/climateSummary/${id}`)
    }

    /**
     * Gets computed climate by profile Id
     * @param id
     */
    getComputedClimate(id: string) {
        return this.authenticatedGetJson<{data: ClimateProfileData}>(`/computed/climate/${id}`)
    }

    /**
     * Downloads csv template
     */
    async downloadCsvTemplate() {
        const baseUrl = await UrlConfig.getBaseUrl(this.endpoint)

        const response = await fetch(`${baseUrl}/static/weather-data/csv-template`, {
            method: "GET"
        })
        const blob = await response.blob()
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'csv_template.csv')
        link.click()
        window.URL.revokeObjectURL(url)
    }


    /**
     *
     * @param siteId
     */
    async ekkoSoftSensorInformation(siteId: string) {
        const responseJson = await this.authenticatedGetJson<{data: SensorInformation}>(`/ekkoSoft/sensorInformation/${siteId}`)

        responseJson.data.items.forEach(i => i.id = i.id.toString())

        return responseJson.data
    }

    /**
     *
     */
    async globalAccessLevel() {
        const result = await this.authenticatedGetJson<{data: {otherOrganisations: boolean, ownOrganisationToOthers: boolean}}>(`/accessLevel/global`)

        return result.data
    }

    /**
     *
     * @param siteId
     */
    async cbreSensorInformation(siteId: string) {
        const result = await this.authenticatedGetJson<{data: SensorInformation}>(`/cbre/sensorInformation/${siteId}`)

        return result.data
    }

    /**
     * Trigger the token exchanger middleware for the auth code verification.
     * This verification response returns a token (id-token) which is stored in the session database
     * and the session id will be added to the cookie.
     * @param code
     */
    async isValidAuthCode(code: string): Promise<boolean> {
        try {
            // This does not use authentication
            const response = await fetch(`/authentication/getToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code: code })
            })

            if (response.status === 401) {
                return false
            }
            const { accessToken, refreshToken } = await response.json()

            if (accessToken) {
                sessionStorage.setItem(BaseHttpService.sessionName, accessToken)
                sessionStorage.setItem(BaseHttpService.refreshToken, refreshToken)
            }
            return true
        }
        catch (e) {
            return false
        }
    }

    /**
     *
     * @param organisationId
     */
    async organisationAccessLevel(organisationId: string) {
        const result = await this.authenticatedGetJson<{data: {read: boolean, write: boolean}}>(`/accessLevel/organisation/${organisationId}`)

        return result.data
    }

    /**
     *
     * @param siteId
     */
    async siteAccessLevel(siteId: string) {
        const result = await this.authenticatedGetJson<{data: {read: boolean, write: boolean}}>(`/accessLevel/site/${siteId}`)

        return result.data
    }

    /**
     * Gets weather metered readings
     * @param siteId
     * @param from
     * @param to
     */
    @cacheWrap(3600)
    async getWeatherMeteredReadings(siteId: string, from: string, to: string) {
        const result = await this.authenticatedGetJson<{data: WeatherReading[]}>(`/other/site/${siteId}/weather/forLocation?from=${from}&to=${to}`)

        return result.data
    }
}

export const httpService = new HttpService()
