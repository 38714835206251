import { ReadingType } from "@/History"
import {
    ClimateWidgetData,
    FilterType,
    Granularity,
    NamedSimulationNode,
    QuickModeProps,
    QuickModeSliderValues,
    QuickModeSplitterConfig,
    SiteParameter,
    TimeBasedParameter,
    ViewType
} from "@/Simulation"
import { WorkloadMaxType } from "@/Simulation/enums/WorkloadMaxType"
import { FiltersCalculationHelper } from "@/Simulation/helpers"
import { PeriodSimulationCallHelper } from "@/Simulation/helpers/PeriodSimulationCallHelper"
import { PointSimulationCallHelper } from "@/Simulation/helpers/PointSimulationCallHelper"
import { QuickModeHelper } from "@/Simulation/helpers/QuickModeHelper"
import {
    CalibrationStatusFilter,
    SimulationOutputFilter,
    SimulationOverTimeFilter
} from "@/Simulation/helpers/precalculatedData"
import { SimulationWidgetData } from "@/Simulation/interfaces/SimulationWidgetData"
import {
    DateHelper,
    IterableHelper,
    NodeHelper,
    NodeTooltipHelper,
    NodesetHelper,
    VueLazy,
    vueLazySetProperty
} from "@/core/helpers"
import { ReadingBatchResults } from "@/core/helpers/ReadingsBatchResults"
import { CanvasEvent, GraphData, MeteredData, ProvisioningState, Reading, WeatherReading } from "@/core/interfaces"
import { Temperature } from "@/core/interfaces/Temperature"
import { httpService, jsonRpcService, powerSimulatorService } from "@/core/services"
import { Models } from "@ekko/predict-client-api"
import { Interface } from "predict-performance-calculation"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { AdviseProvisioning } from "../../Simulation/helpers/AdviseProvisioning"
import { NodesDataFetcher } from "../../Simulation/helpers/NodesDataFetcher"
import { ActualExpected } from "../../Simulation/interfaces/filters"
import { Preload } from "../../core/helpers/Preload"
import { SimulationNodeTypeHelper } from "../SimulationNodeTypeHelper"
import {
    SimulationMode,
    SimulationNodeType,
    SimulationSubNav,
    SimulationViewStateActions,
    SimulationViewStateGetters,
    SimulationViewStateMutations
} from "../SimulationViewState"
import {
    AnyNode,
    SiteConfigurationScenario,
    SiteHeader,
    SiteViewState,
    SiteViewStateActions,
    SiteViewStateGetters,
    SiteViewStateMutations
} from "../SiteViewState"
import { PowerDemandHelper } from "./PowerDemandHelper"
import {
    CustomPeriod,
    EnvironmentDataSource,
    GraphInfo,
    NodeReadings,
    ProgressInfo,
    SimulationData,
    SimulationOverTime,
    TemporalData,
    TimeBasedData,
    TimelineBarData,
    WorkloadDataSource
} from "./types"

/**
 *
 */
const loadProvisioningAdviceSource: WorkloadMaxType = WorkloadMaxType.peak

/**
 *
 */
let warnedDefaultWeather = false

/**
 *
 */
const DefaultWeather = { dryBulb: 20, wetBulb: 23 }

/**
 *
 */
const DefaultQuickModeSliderValues: QuickModeSliderValues = {
    temperature: 35,
    humidity: 80,
}

/**
 *
 * @param timeBasedData
 * @param timeBasedParameter
 * @param slice
 * @param storeResult
 * @returns
 */
function getSimResults(timeBasedData: TimeBasedData, timeBasedParameter: TimeBasedParameter, slice: Date, storeResult: (slice: Date, value: Partial<TemporalData>) => any) {
    if (timeBasedData.weather) {
        const weather = {
            dryBulb: timeBasedData.weather?.dryBulb,
            wetBulb: timeBasedData.weather?.wetBulb,
        } as Temperature

        const fullItLoad = timeBasedParameter.getCurrentFullStaticLoad()

        return Promise.all([
            powerSimulatorService.simulateBatchPossible(
                timeBasedParameter.nodes,
                weather,
                timeBasedData.itLoad,
                timeBasedData.provisioning
            ),
            powerSimulatorService.simulateBatchPossible(
                timeBasedParameter.nodes,
                weather,
                fullItLoad,
                timeBasedData.provisioning
            )
        ]).then(
            ([simulationData, provisionedSimulationData]) => {
                const value = {
                    provisionedSimulationData,
                    simulationData,
                    timeBasedData,
                }
                storeResult(slice, value)
            },
            (e) => {
                console.error(e)
                console.warn("Power simulator service is unreachable")
                const value = {
                    provisionedSimulationData: null,
                    simulationData: null,
                    timeBasedData,
                }
                storeResult(slice, value)
            }
        )
    } else {
        const value = {
            simulationData: null,
            timeBasedData,
            provisionedSimulationData: null,
        }
        storeResult(slice, value)
        return undefined
    }
}

/**
 *
 */
interface StoredNodesData {
    /**
     *
     * @param nodes
     */
    matches(nodes: AnyNode[]): boolean
    /**
     *
     */
    readonly revision: number
}

/**
 *
 */
export interface StoredNodesDataPromise extends StoredNodesData {
    /**
     *
     */
    promise: Promise<NamedSimulationNode[]>
}

/**
 *
 */
export interface StoredNodesDataResult extends StoredNodesData {
    /**
     *
     */
    result: NamedSimulationNode[]
}

class State extends SiteViewState {
    /**
     *
     */
    calibrationBound: number | null = null

    /**
     *
     */
    climateWidgetData: ClimateWidgetData | null = null

    /**
     *
     */
    graphCustomPeriod: CustomPeriod | null = null

    /**
     *
     */
    graphInfo: GraphInfo | null = null

    /**
     * When an error occurred in a headless action, it'll appear here.
     */
    headlessError: any | null = null

    /**
     *
     */
    isProvisioningAdviceAvailable = false

    /**
     *
     */
    lockedProvisioning: Set<string> = new Set()

    /**
     *
     */
    modelCapacities: Map<string, number> | null = null

    /**
     *
     */
    nodeReadings: NodeReadings | null = null

    /**
     *
     */
    nodesDataFetcher = new NodesDataFetcher()

    /**
     *
     */
    nodesetsCached: VueLazy<SimulationData[] | null> = null

    /**
     *
     */
    provisionedSimulationData: SimulationData | null = null

    /**
     *
     */
    provisioningAdvice: Record<string, {from: number, to: number}> | null = null

    /**
     *
     */
    quickModeProps: QuickModeProps = {
        itLoad: null,
        provisionedItLoad: null,
        provisioning: null,
        splitterConfig: null,
        weather: null,
    }

    /**
     *
     */
    quickModeSliderValues: QuickModeSliderValues | null = null

    /**
     *
     */
    replacedSiteConfigurationsScenario: string | null = null

    /**
     *
     */
    simulationData: SimulationData | null = null

    /**
     *
     */
    simulationOverTime: SimulationOverTime | null = null

    /**
     *
     */
    simulationOverTimeProgressInfo: ProgressInfo | null = null

    /**
     *
     */
    actualVersusExpected: ActualExpected | null = null

    /**
     *
     */
    actualVersusExpectedProgressInfo: ProgressInfo | null = null

    /**
     *
     */
    simulationWidgetData: SimulationWidgetData | null = null

    /**
     *
     */
    siteConfigurationsScenario: SiteConfigurationScenario[] = []

    /**
     * The simulation conditions at the point in time being examined
     */
    timeBasedData: TimeBasedData | null = null

    /**
     *
     */
    timeBasedParameter: TimeBasedParameter | null = null

    /**
     *
     */
    timeBounds: { lower: Date, upper: Date } | null = null

    /**
     *
     */
    timelineBarData: TimelineBarData | null = null

    /**
     *
     */
    timePoint: Date = new Date()

    /**
     *
     */
    weatherReadings: WeatherReading[] | null = null
}

/**
 *
 */
const Getters: GetterTree<State, any> = {
    /**
     * @param state
     * @param getters
     */
    aggregateGraphData(state, getters) {
        /**
         * @param records
         */
        return (records: GraphData[]) => {
            switch (getters.sidePanelGraphGranularity) {
                case Granularity.DAY:
                    return IterableHelper.aggregate(records,
                        record => {
                            const d = new Date(record.timestamp)
                            // Pretend it's UTC to get the ISO date
                            d.setMinutes(d.getMinutes() - d.getTimezoneOffset())
                            return d.toISOString().replace(/T.*/, "")
                        },
                        (to, from) => to.value += from.value,
                        (aggregatedRecord, count, key) => ({
                            timestamp: key,
                            value: aggregatedRecord.value / count,
                        })
                    )
                case Granularity.HOUR:
                    return records
                default:
                    return records
            }
        }
    },
    /**
     *
     * @param state
     * @returns
     */
    allowedMeteredData(state): MeteredData | null {
        if (!state.graphInfo?.meteredData) return null
        const meteredData = state.graphInfo.meteredData
        const timelineBarData = state.timelineBarData
        return {
            meteredTemperature: timelineBarData?.environmentDataSource == EnvironmentDataSource.WEATHER ?
                meteredData.meteredTemperature :
                null,
            readings: state.timelineBarData?.workloadDataSource == WorkloadDataSource.READINGS ?
                meteredData.readings :
                new Map(),
        }
    },
    /**
     * Returns the sim value of a node or any counterparts
     *
     * @param state
     * @param getters
     */
    combinedSimValue(state, getters) {
        return (simulationData: SimulationData | null | undefined,
            node: NamedSimulationNode
        ): [number, number, number] | null | undefined => {
            if (!simulationData) return null
            let result: [number, number, number] | undefined
            for (const nodeRef of getters.nodeRefsFor(node)) {
                const resultIn = simulationData[nodeRef]
                if (resultIn) {
                    if (!result) result = [0, 0, 0]
                    result = [
                        result[0] + resultIn[0],
                        result[1] + resultIn[1],
                        result[2] + resultIn[2],
                    ]
                }
            }
            return result
        }
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getReadingsInTimelinePeriod(state, getters) {
        /**
         * @param nodeReferences
         */
        return (...nodeReferences: string[]) => {
            if (state.nodeReadings && getters.timelineBarDates) {
                const from = getters.timelineBarDates.from
                const to = getters.timelineBarDates.to
                let readings: Reading<Date>[] | undefined
                for (const nodeReference of nodeReferences) {
                    if (state.nodeReadings[nodeReference]) {
                        readings = (readings || []).concat(state.nodeReadings[nodeReference])
                    }
                }
                readings?.sort((a, b) => a.timestamp.valueOf() - b.timestamp.valueOf())
                const seenReadings = new Set<string>()

                return readings?.filter((reading) => reading.timestamp >= from && reading.timestamp <= to).filter(
                    reading => {
                        const uid = JSON.stringify([reading.timestamp, reading.type])
                        if (seenReadings.has(uid)) return false
                        seenReadings.add(uid)
                        return true
                    }
                )
            }
            return null
        }
    },
    /**
     * @param state
     * @param getters
     * @returns
     */
    graphGranularity(state, getters) {
        if (!getters.timelineBarDates) {
            return Granularity.HOUR
        }
        const fromPlus30Days = new Date(getters.timelineBarDates.from)
        fromPlus30Days.setDate(fromPlus30Days.getDate() + 30)

        if (fromPlus30Days <= getters.timelineBarDates.to) {
            return Granularity.DAY
        } else {
            return Granularity.HOUR
        }
    },

    /**
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     */
    headerInformation(state, getters, rootState, rootGetters): SiteHeader | null {
        const baseHeaderInformation = SiteViewStateGetters.headerInformation(state, getters, rootState, rootGetters)
        if (state.activePanel.mode == SimulationMode.ADV && state.siteConfigurationsScenario.length) {
            const configurationName =
                state.siteConfigurationsScenario[0].model.getName()
            return {
                ...baseHeaderInformation,
                configurationName,
                configurationsCount: state.siteConfigurationsScenario.length,
            }
        }
        return baseHeaderInformation
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    nodeRefsFor(state, getters) {
        return (node: NamedSimulationNode) =>
            NodeHelper.getNodeReferences(node, node, getters.nodesets)
    },
    /**
     *
     * @param state
     */
    nodesData(state) {
        if (!state.nodes) {
            return null
        }
        return state.nodesDataFetcher.cached(state.nodes)
    },
    /**
     *
     * @param state
     * @param getters
     */
    nodeTooltip(state, getters) {
        /**
         * @param node
         * @returns
         */
        return (node: AnyNode): CanvasEvent.ShowTooltip => ({
            id: node.id, ...NodeTooltipHelper.getTooltipContent(node,
                state.simulationData, state.timeBasedData, getters.nodesData,
                state.quickModeProps)})
    },
    /**
     * Right side panel graph granularity
     *
     * @param state
     * @param getters
     * @returns
     */
    sidePanelGraphGranularity(state, getters) {
        if (!getters.timelineBarDates) {
            return Granularity.HOUR
        }

        const fromPlus14Days = new Date(getters.timelineBarDates.from)
        fromPlus14Days.setDate(fromPlus14Days.getDate() + 14)

        if (fromPlus14Days <= getters.timelineBarDates.to) {
            return Granularity.DAY
        } else {
            return Granularity.HOUR
        }
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    riskIssueNodesCount(state) {
        if (state.filterCalculation) {
          let riskIssueNodesLength = 0
          for (const [_, calculation] of Object.entries(state.filterCalculation)) {
            const isOverload = calculation.utilised >= 100
            isOverload && riskIssueNodesLength++
          }
          return riskIssueNodesLength
        }
        return 0
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    nodesets(state, getters) {
        if (!state.nodesetsCached) {
            vueLazySetProperty(
                state,
                "nodesetsCached",
                () => getters.nodesetsAsync
            )
        }
        return state.nodesetsCached!.value
    },
    /**
     *
     * @param state
     * @returns
     */
    async nodesetsAsync(state) {
        if (state.activePanel.mode == SimulationMode.ADV) {
            return NodesetHelper.getNodesetsForAdvanced(state.siteConfigurationsScenario)
        } else if (state.activePanel.mode == SimulationMode.QUICK) {
            const nodeset = await NodesetHelper.getNodesetForSiteConfiguration(state.siteConfiguration!)
            return [
                {
                    ...nodeset,
                    start: new Date("2000-01-01"),
                    finish: null,
                }
            ]
        } else if (state.site) {
            return NodesetHelper.getNodesetsForSite(state.site)
        } else {
            return null
        }
    },
    /**
     *
     * @param state
     * @returns
     */
    timelineBarDates(state) {
        if (!state.timelineBarData) {
            return null
        }
        return {
            from: new Date(state.timelineBarData.from),
            to: new Date(state.timelineBarData.to),
        }
    },
}

const Mutations: MutationTree<State> = {
    /**
     * @param state
     * @param progressMax
     */
    reloadingSimulationOverTime(state, progressMax: number) {
        state.simulationOverTimeProgressInfo = { current: 0, max: progressMax }
    },
    /**
     * @param state
     * @param progressMax
     */
    reloadingActualVersusExpected(state, progressMax: number) {
        state.actualVersusExpectedProgressInfo = { current: 0, max: progressMax }
    },
    /**
     *
     * @param state
     * @param graphInfo
     */
    setGraphInfo(state, graphInfo: GraphInfo | null) {
        state.graphInfo = graphInfo
    },
    /**
     *
     * @param state
     * @param e
     */
    setHeadlessError(state, e: any | null) {
        state.headlessError = e
    },
    /**
     *
     * @param state
     * @param isAvailable
     */
    setIsProvisioningAdviceAvailable(state, isAvailable: boolean) {
        state.isProvisioningAdviceAvailable = isAvailable
    },
    /**
     *
     * @param state
     * @param nodeRefLock
     */
    setLockProvisioning(state, {nodeRef, lock}: {nodeRef: string, lock: boolean}) {
        if(lock) {
            state.lockedProvisioning.add(nodeRef)
        } else {
            state.lockedProvisioning.delete(nodeRef)
        }
    },
    /**
     * @param state
     * @param mode
     */
    setMode(state, mode: SimulationMode) {
        state.activePanel.mode = mode
        state.activePanel.subNav = SimulationSubNav.INFO
        state.activePanel.nodeType = null
        state.nodesetsCached = null
    },
    /**
     * @param state
     * @param nodes
     */
    setNodes(state, nodes: AnyNode[]) {
        SiteViewStateMutations.setNodes(state, nodes)
        state.lockedProvisioning = new Set()
        state.modelCapacities = null
        state.nodeReadings = {}
        state.provisioningAdvice = null
        state.timeBasedParameter = null
    },
    /**
     *
     * @param state
     * @param advice
     */
    setProvisioningAdvice(state, advice: Record<string, {from: number, to: number}> | null) {
        state.provisioningAdvice = advice
        state.isProvisioningAdviceAvailable = false
    },
    /**
     *
     * @param state
     * @param progress
     */
    setSimulationOverTimeProgress(state, progress: number) {
        if (state.simulationOverTimeProgressInfo) {
            state.simulationOverTimeProgressInfo.current = progress
        }
    },
    /**
     *
     * @param state
     * @param progress
     */
    setActualVersusExpectedProgress(state, progress: number) {
        if (state.actualVersusExpectedProgressInfo) {
            state.actualVersusExpectedProgressInfo.current = progress
        }
    },
    /**
     * @param state
     * @param site
     */
    setSite(state, site: Models.SiteModel) {
        SiteViewStateMutations.setSite.call(this, state, site)
        state.siteConfigurationsScenario = []
        state.nodesetsCached = null
    },
    /**
     * @param state
     * @param siteConfiguration
     */
    setSiteConfiguration(state, siteConfiguration: Models.SiteConfigurationModel) {
        const oldSiteConfiguration = state.siteConfiguration
        SiteViewStateMutations.setSiteConfiguration.call(this, state,
            siteConfiguration)
        if(oldSiteConfiguration?.id !== siteConfiguration?.id) {
            // Only if different - this allows us to reload metadata
            state.nodesDataFetcher.clear()
            state.nodesetsCached = null
        }
    },
    /**
     *
     * @param state
     * @param timelineBarData
     */
    setTimelineBarData(state, timelineBarData: TimelineBarData | null) {
        state.timelineBarData = timelineBarData
        state.provisioningAdvice = null
    },
    /**
     *
     * @param state
     * @param temporalData
     */
    setTemporalData(state, temporalData: TemporalData) {
        state.timeBasedData = temporalData.timeBasedData
        state.simulationData = temporalData.simulationData ?? null
        state.provisionedSimulationData = temporalData.provisionedSimulationData ?? null
        state.provisioningAdvice = null
        state.simulationOverTime = null
    },
    /**
     *
     * @param state
     * @param simulationOverTime
     */
    setSimulationOverTime(state, simulationOverTime: SimulationOverTime | null) {
        if (simulationOverTime) {
            state.simulationOverTime = {
                ...(state.simulationOverTime || {}),
                ...simulationOverTime,
            }
        } else {
            state.simulationOverTime = null
        }
    },
    /**
     *
     * @param state
     * @param actualVersusExpected
     */
    setActualVersusExpected(state, actualVersusExpected: ActualExpected | null) {
        if (actualVersusExpected) {
            state.actualVersusExpected = {
                ...(state.actualVersusExpected || {}),
                ...actualVersusExpected,
            }
        } else {
            state.actualVersusExpected = null
        }
    },
    /**
     *
     * @param state
     * @param timePoint
     */
    setTimePoint(state, timePoint: Date) {
        state.timePoint = timePoint

        const fuzzHours = 0 // Set this to 1 or more to create bounds of +/- n hours

        const lower = new Date(timePoint)
        lower.setHours(timePoint.getHours() - fuzzHours, 0, 0, 0)
        const upper = new Date(timePoint)
        upper.setHours(timePoint.getHours() + fuzzHours + 1, 0, 0, 0)
        state.timeBounds = { lower, upper }
    },
    /**
     * @param state
     * @param modelCapacities
     */
    setModelCapacities(state, modelCapacities: Map<string, number>) {
        state.modelCapacities = modelCapacities
    },
    /**
     * @param state
     * @param nodeReadings
     */
    setNodeReadings(state, nodeReadings: NodeReadings | null) {
        state.nodeReadings = nodeReadings
    },
    /**
     * @param state
     * @param weatherReadings
     */
    setWeatherReadings(state, weatherReadings: WeatherReading[] | null) {
        state.weatherReadings = weatherReadings
    },
    /**
     * @param state
     * @param calibrationBound
     */
    setCalibrationBound(state, calibrationBound: number | null) {
        state.calibrationBound = calibrationBound
    },
    /**
     *
     * @param state
     * @param siteConfigurationScenarios
     */
    setSiteConfigurationScenarios(state, siteConfigurationScenarios: SiteConfigurationScenario[]) {
        state.siteConfigurationsScenario = siteConfigurationScenarios
    },
    /**
     * @param state
     * @param text
     */
    setSiteConfigurationSearchText(state, text: string) {
        state.siteConfigurationSearch = text
    },
    /**
     *
     * @param state
     * @param siteConfiguration
     */
    setReplacedSiteConfigurationScenario(state, id: string | null) {
        state.replacedSiteConfigurationsScenario = id
    },
    /**
     *
     * @param state
     * @param climateWidgetData
     */
    setClimateWidgetData(state, climateWidgetData: ClimateWidgetData) {
        state.climateWidgetData = climateWidgetData
    },
    /**
    *
    * @param state
    * @param simulationWidgetData
    */
    setSimulationWidgetData(state, simulationWidgetData: SimulationWidgetData) {
        state.simulationWidgetData = simulationWidgetData
    },
    /**
     *
     * @param state
     * @param itLoad
     */
    setQuickModeItLoad(state, itLoad: { [ref: string]: number } | null) {
        state.quickModeProps.itLoad = itLoad
    },
    /**
     *
     * @param state
     * @param itLoad
     */
    setQuickModeProvisionedItLoad(state, itLoad: { [ref: string]: number } | null) {
        state.quickModeProps.provisionedItLoad = itLoad
    },
    /**
     *
     * @param state
     * @param provisioning
     */
    setQuickModeProvisioning(state, provisioning: { [ref: string]: ProvisioningState } | null) {
        state.quickModeProps.provisioning = provisioning
    },
    /**
     *
     * @param state
     * @param splitterConfig
     */
    setQuickModeSplitterConfig(state, splitterConfig: { [ref: string]: QuickModeSplitterConfig } | null) {
        state.quickModeProps.splitterConfig = splitterConfig
    },
    /**
     *
     * @param state
     * @param weather
     */
    setQuickModeWeather(state,
        weather: Interface.Temperature & { relativeHumidity?: number } | null) {
        state.quickModeProps.weather = weather
    },
    /**
     *
     * @param state
     * @param values
     */
    setQuickModeSliderValues(state, values: QuickModeSliderValues | null) {
        state.quickModeSliderValues = values
    },
    /**
     * @param state
     * @param timeBasedParameter
     */
    setTimeBasedParameter(state, timeBasedParameter: TimeBasedParameter | null) {
        state.timeBasedParameter = timeBasedParameter
    },
    /**
     * @param state
     * @param customPeriod
     */
    setGraphCustomPeriod(state, customPeriod: State["graphCustomPeriod"]) {
        state.graphCustomPeriod = customPeriod
    },
}

const Actions: ActionTree<State, any> = {
    /**
     *
     * @param actionState
     * @param refLoad
     */
    async addQuickModeItLoad({ commit, dispatch, state },
        { ref, load }: { ref: string, load: number }
    ) {
        const itLoad = state.quickModeProps.itLoad || state.timeBasedData?.itLoad || {}
        commit("setQuickModeItLoad", { ...itLoad, [ref]: load })
        await dispatch("performCurrentQuickSimulation")
    },
    /**
     *
     * @param actionState
     * @param refLoad
     */
    async addQuickModeProvisionedItLoad({ commit, dispatch, state },
        { ref, load }: { ref: string, load: number }
    ) {
        const itLoad = state.quickModeProps.provisionedItLoad || state.timeBasedParameter?.getCurrentFullStaticLoad() || {}
        commit("setQuickModeProvisionedItLoad", { ...itLoad, [ref]: load })
        await dispatch("performCurrentQuickSimulation")
    },
    /**
     *
     * @param actionState
     * @param refProvisioning
     */
    async addQuickModeProvisioning({ commit, dispatch, state },
        { ref, provisioning }: { ref: string, provisioning: ProvisioningState }
    ) {
        const allProvisioning = state.quickModeProps.provisioning || state.timeBasedData?.provisioning || {}
        commit("setQuickModeProvisioning", { ...allProvisioning, [ref]: provisioning })
        await dispatch("performCurrentQuickSimulation")
    },
    /**
     *
     * @param actionState
     * @param nodes
     */
    applyQuickModeProvisioningAdvice({ commit, dispatch, state }, nodes?: Set<string>) {
        if(!state.provisioningAdvice) {
            throw new Error("No provisioning advice to apply")
        }
        const provisioningAdvice = state.provisioningAdvice
        const allProvisioning = state.quickModeProps.provisioning || state.timeBasedData?.provisioning || {}
        const newProvisioning: typeof allProvisioning = {}
        for (const [nodeRef, advice] of Object.entries(provisioningAdvice)) {
            if (advice && (!nodes || nodes.has(nodeRef))) {
                newProvisioning[nodeRef] = { count: advice.to }
            }
        }
        commit("setQuickModeProvisioning", { ...allProvisioning,
            ...newProvisioning })
        return dispatch("performCurrentQuickSimulation")
    },
    /**
     * @param actionState
     * @param advicePositiveOnly
     */
    async checkProvisioning({ state }, advicePositiveOnly = false) {
        if (!state.nodes || !state.timeBasedData) {
            throw new Error("Cannot check - nodes not loaded")
        }
        const baseCallHelper = new PeriodSimulationCallHelper(state.nodes,
            state.timePoint, state.site, state.timeBasedData)
        const callHelper = state.activePanel.mode == SimulationMode.QUICK ?
            new QuickModeHelper(state.nodes, state.quickModeProps,
                baseCallHelper) : baseCallHelper
        return AdviseProvisioning.adviseForSite(
            state.timeBasedData, state.nodes, state.nodesDataFetcher,
            callHelper, loadProvisioningAdviceSource == WorkloadMaxType.peak ?
                await callHelper.getItLoad() :
                await callHelper.getProvisionedItLoad(),
                state.lockedProvisioning, !advicePositiveOnly)
    },
    /**
     *
     * @param actionState
     */
    hideProvisioningAdvice({ commit }) {
        commit("setIsProvisioningAdviceAvailable", false)
    },
    /**
     *
     * @param actionState
     * @param advicePositiveOnly
     */
    async injectProvisioningAdvice({commit, state}, advicePositiveOnly = false) {
        if (!state.nodes || !state.timeBasedData) {
            throw new Error("Cannot check - nodes not loaded")
        }
        commit("setProvisioningAdvice", null)
        const baseCallHelper = new PeriodSimulationCallHelper(state.nodes,
            state.timePoint, state.site, state.timeBasedData)
        const callHelper = state.activePanel.mode == SimulationMode.QUICK ?
            new QuickModeHelper(state.nodes, state.quickModeProps,
                baseCallHelper) : baseCallHelper
        const advice = await AdviseProvisioning.adviseForSite(
            state.timeBasedData, state.nodes, state.nodesDataFetcher,
            callHelper, loadProvisioningAdviceSource == WorkloadMaxType.peak ?
                await callHelper.getItLoad() :
                await callHelper.getProvisionedItLoad(),
                state.lockedProvisioning, !advicePositiveOnly)

        commit("setProvisioningAdvice", advice)
    },
    /**
     *
     * @param actionState
     * @param refProvisioning
     */
    async resetQuickModeProvisioning({ commit, dispatch, state }) {
        const allProvisioning = state.timeBasedData?.provisioning || {}
        commit("setQuickModeProvisioning", { ...allProvisioning })
        await dispatch("performCurrentQuickSimulation")
    },
    /**
     * @param actionState
     */
    async showProvisioningAdvice({ commit, dispatch }) {
        await dispatch("injectProvisioningAdvice")
        commit("setIsProvisioningAdviceAvailable", true)
    },
    /**
     *
     * @param actionState
     * @param refLoad
     */
    async addQuickModeSplitterConfig({ commit, dispatch, state },
        { ref, config }: { ref: string, config: QuickModeSplitterConfig }
    ) {
        const splitterConfig = state.quickModeProps.splitterConfig || {}
        commit("setQuickModeSplitterConfig", { ...splitterConfig, [ref]: config })
        await dispatch("performCurrentQuickSimulation")
    },
    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async addSiteConfigurationScenario({ dispatch, state }, siteConfiguration: Models.SiteConfigurationModel) {
        await dispatch("updateSiteConfigurationScenarios", [
            ...state.siteConfigurationsScenario,
            {
                model: siteConfiguration,
                dateFrom: "",
                dateTo: ""
            }
        ])
    },
    /**
     *
     * @param param0
     */
    clearHeadlessError({ commit }) {
        commit("setHeadlessError", null)
    },
    /**
     *
     * @param actionState
     */
    clearNodeReadings({ commit }) {
        commit("setNodeReadings", null)
    },
    /**
     *
     * @param actionState
     */
    async clearTimelineBarData({ commit, dispatch }) {
        commit("setTimelineBarData", null)
        dispatch("clearTimeBasedParameter")
        await dispatch("buildTimeBasedData")
        await dispatch("performCurrentSimulation")
    },
    /**
     *
     * @param actionState
     */
    clearTimeBasedParameter({ commit }) {
        commit("setTimeBasedParameter", null)
    },

    /**
     * Sets up quick mode
     */
    async initQuickMode({ commit, dispatch, state }) {
        if (!state.timeBasedParameter) {
            throw new Error("No time-based parameter to initialise quick mode from")
        }
        const fullITLoad = state.timeBasedParameter.getCurrentFullStaticLoad()
        const itLoad = state.timeBasedParameter.getCurrentStaticLoad()
        const provisioning = state.timeBasedParameter.getCurrentProvisioning()
        commit("setQuickModeProvisioning", provisioning)
        commit("setQuickModeProvisionedItLoad", fullITLoad)
        commit("setQuickModeItLoad", itLoad)
        commit("setQuickModeSliderValues", { ...DefaultQuickModeSliderValues })
        await dispatch("performCurrentQuickSimulation")
    },
    /**
     *
     * @param actionState
     * @param mode
     */
    setMode({ commit, dispatch, state }, mode: SimulationMode) {
        commit("setMode", mode)

        const filterMode = state.filter.Node?.[0] as ViewType | undefined
        if (filterMode !== FilterType.COMBINED_CAPACITY) {
            dispatch("updateFilter", { Node: [FilterType.COMBINED_CAPACITY] })
        }

        dispatch("updateTimePoint", new Date())
    },
    /**
     *
     * @param actionState
     * @param id
     */
    setReplacedSiteConfigurationScenario({ commit }, id: string) {
        commit("setReplacedSiteConfigurationScenario", id)
    },
    /**
     *
     * @param actionState
     * @param nodeRefLock
     */
    updateLockProvisioning({ commit }, update: {nodeRef: string, lock: boolean}) {
        commit("setLockProvisioning", update)
    },
    /**
     *
     * @param actionState
     * @param id
     */
    async replaceSiteConfigurationScenario({ commit, dispatch, state }, siteConfiguration: SiteConfigurationScenario) {
        const scenarios = state.siteConfigurationsScenario.map(s => {
            if (s.model.id == state.replacedSiteConfigurationsScenario) {
                return {
                    ...s,
                    model: siteConfiguration,
                }
            } else {
                return s
            }
        })
        await dispatch("updateSiteConfigurationScenarios", scenarios)
        commit("setReplacedSiteConfigurationScenario", null)
    },
    /**
     *
     * @param param0
     * @param dates
     */
    async getWeatherReadings({ commit, state }, { from, to }: { from: Date, to: Date }) {
        if (!state.site) {
            throw new Error(`Cannot fetch weather readings without site`)
        }
        const weatherReadings = await httpService.getWeatherMeteredReadings(
            state.site.id, from.toISOString(), to.toISOString())
        commit("setWeatherReadings", weatherReadings)
    },
    /**
     *
     * @param param0
     * @param granularity
     */
    async getNodeReadings({ commit, state, getters },
        granularity: Granularity = Granularity.HOUR,
    ) {
        const nodeReadings: NodeReadings = {}
        let warnedBadMeterMappingType = false

        if (state.timelineBarData && state.nodes) {
            const nodesData = await state.nodesDataFetcher.fetchCached(state.nodes)
            const { from, to } = state.timelineBarData
            const startTimelineBarDate = new Date(from)
            const endTimelineBarDate = new Date(to)

            const period = {
                start: DateHelper.fullYearMonthOnly(new Date(from)),
                months: (endTimelineBarDate.getFullYear() - startTimelineBarDate.getFullYear()) * 12 +
                    endTimelineBarDate.getMonth() - startTimelineBarDate.getMonth() + 1,
            }
            const nodesets = await getters.nodesetsAsync

            const allNodeReadings = await ReadingBatchResults.iterate(
                nodesets,
                async () => {
                    try {
                        return await jsonRpcService.getBatchNodeReadings(
                            nodesData.map(node => `${node.type}/${node.id}`),
                            period,
                            granularity,
                        )
                    } catch(e) {
                        console.error(e)
                        return {}
                    }
                },
                granularity
            )

            for (const node of nodesData) {
                const nodeReference = `${node.type}/${node.id}`
                const readings = allNodeReadings[nodeReference]

                if (readings) {
                    nodeReadings[nodeReference] = []
                    for (const reading of readings) {
                        if (reading.type === ReadingType.POWER) {
                            if (!warnedBadMeterMappingType) {
                                console.warn(`Node ${nodeReference} has misconfigured meter mapping, assuming power supply`)
                                warnedBadMeterMappingType = true
                            }
                            reading.type = ReadingType.POWER_SUPPLY
                        }

                        if (reading.type === ReadingType.COOLING) {
                            if (!warnedBadMeterMappingType) {
                                console.warn(`Node ${nodeReference} has misconfigured meter mapping, assuming cooling load`)
                                warnedBadMeterMappingType = true
                            }
                            reading.type = ReadingType.COOLING_LOAD
                        }

                        nodeReadings[nodeReference].push({
                            ...reading,
                            timestamp: new Date(reading.timestamp),
                        })
                    }
                }
            }
        }

        commit("setNodeReadings", nodeReadings)
    },
    /**
     *
     * @param actionState
     */
    async performCurrentQuickSimulation({ dispatch, state }) {
        if (state.activePanel.mode == SimulationMode.QUICK) {
            await dispatch("performCurrentSimulation")
        }
    },
    /**
     * @param actionState
     * @param filter
     */
    async updateFilter({ commit, dispatch, state }, filter: { [section: string]: string[] }) {
        commit("setFilter", filter)

        // Set here so that they don't get raced
        const simulationData = state.simulationData
        const timeBasedDataIn = state.timeBasedData
        const provisionedSimulationData = state.provisionedSimulationData
        const nodes = state.nodes

        if (simulationData && timeBasedDataIn && provisionedSimulationData &&
            nodes)
        {
            const nodesDataIn = await state.nodesDataFetcher.fetchCached(nodes)
            let modelCapacities = state.modelCapacities
            if (!modelCapacities) {
                modelCapacities = new Map<string, number>()
                for (const node of nodes) {
                    let capacity: number
                    if (node instanceof Models.ItemModel) {
                        const cmode = await node.getCustomDeviceMode()
                        let customModeCapacity: number | null = null
                        if (cmode) {
                            const mode = await cmode?.getDeviceMode()
                            customModeCapacity = mode?.getCapacity()
                        }
                        if (customModeCapacity) {
                            capacity = customModeCapacity
                        } else {
                            const mode = await node.getDeviceMode()
                            capacity = mode.getCapacity() ?? 0
                        }
                    } else {
                        capacity = 0
                    }
                    modelCapacities.set(node.type + "/" + node.id, capacity)
                }
                commit("setModelCapacities", modelCapacities)
            }
            const expectedNodeReadings = () => {
                if (!state.nodeReadings) {
                    console.warn("Power node readings are not available, using empty set")
                }
                return state.nodeReadings || {}
            }
            const baseCallHelper = new PointSimulationCallHelper(nodes,
                timeBasedDataIn, state.timeBasedParameter)
            const callHelper = state.activePanel.mode == SimulationMode.QUICK ?
                new QuickModeHelper(nodes, state.quickModeProps,
                    baseCallHelper) : baseCallHelper
            const timeBasedData = {
                ...timeBasedDataIn,
                itLoad: await callHelper.getItLoad(),
                provisioning: callHelper.getProvisioning(),
                weather: await callHelper.getWeather(),
            }

            const nodesData = callHelper.getNodesData(nodesDataIn)

            switch (state.filter.Node[0]) {
                case FilterType.ACTUAL_EXPECTED: {
                    await dispatch("updateActualVersusExpected")
                    commit("setFilterCalculation", state.actualVersusExpected)
                    break
                }
                case FilterType.EFFICIENCY:
                    await commit(
                        "setFilterCalculation",
                        FiltersCalculationHelper.efficiency(
                            nodesData,
                            simulationData
                        )
                    )
                    break
                case FilterType.PROVISIONAL_CAPACITY:
                    await commit(
                        "setFilterCalculation",
                        FiltersCalculationHelper.provisionedCapacity(
                            nodesData,
                            provisionedSimulationData,
                            timeBasedData,
                            modelCapacities
                        )
                    )
                    break
                case FilterType.UTILIZED_CAPACITY:
                    await commit(
                        "setFilterCalculation",
                        FiltersCalculationHelper.utilizedCapacity(
                            nodesData,
                            simulationData,
                            timeBasedData,
                            modelCapacities
                        )
                    )
                    break
                case FilterType.COMBINED_CAPACITY:
                    await commit(
                        "setFilterCalculation",
                        FiltersCalculationHelper.combinedCapacity(
                            nodesData,
                            simulationData,
                            provisionedSimulationData,
                            timeBasedData,
                            modelCapacities
                        )
                    )
                    break
                case FilterType.METERING_STATUS:
                    await commit(
                        "setFilterCalculation",
                        await FiltersCalculationHelper.meteringStatus(
                            nodes,
                            expectedNodeReadings(),
                            state.timePoint
                        )
                    )
                    break
                case FilterType.SIMULATION_OUTPUT:
                    await commit(
                        "setFilterCalculation",
                        SimulationOutputFilter.produceData(
                            nodesData,
                            simulationData
                        )
                    )
                    break
                case FilterType.SIMULATION_OVER_TIME:
                    await dispatch("updateSimulationOverTime")
                    await commit(
                        "setFilterCalculation",
                        SimulationOverTimeFilter.produceData(
                            nodesData,
                            state.simulationOverTime!,
                            modelCapacities
                        )
                    )
                    break
                case FilterType.CALIBRATION_STATUS:
                    await commit(
                        "setFilterCalculation",
                        CalibrationStatusFilter.produceData(
                            nodesData,
                            expectedNodeReadings(),
                            simulationData,
                            state.calibrationBound! / 100,
                            state.timePoint
                        )
                    )

                    break
            }
        }
    },
    /**
     *
     * @param param0
     */
    async buildTimeBasedData({ commit, dispatch, getters, state }) {
        if (!state.site) {
            // This happens sometimes on first load
            return
        }
        if (!state.nodes) {
            console.error("No nodes - abort")
            return
        }

        const nodesets = await getters.nodesetsAsync

        if (!nodesets) {
            console.error("No nodesets - abort")
            return
        }

        const getNodeReadings = async () => {
            if (!state.nodeReadings) {
                await dispatch("getNodeReadings")
            }
            return state.nodeReadings
        }
        const getWeatherReadings = async (timelineBarData: TimelineBarData) => {
            if (!state.weatherReadings) {
                await dispatch("getWeatherReadings", {
                    from: new Date(timelineBarData.from),
                    to: new Date(timelineBarData.to),
                })
            }
            return state.weatherReadings
        }
        let timeBasedParameter = state.timeBasedParameter

        if (timeBasedParameter?.matches(
            state.site,
            nodesets,
            state.timelineBarData?.workloadDataSource == WorkloadDataSource.READINGS ?
                await getNodeReadings() :
                null,
            state.timelineBarData?.environmentDataSource == EnvironmentDataSource.WEATHER ?
                await getWeatherReadings(state.timelineBarData) :
                null,
            state.timelineBarData?.environmentDataSource == EnvironmentDataSource.CLIMATE_QUICK,
            state.timePoint,
        )) {
            timeBasedParameter.date = state.timePoint
        } else {
            if (timeBasedParameter) {
                console.log(`Dropping mismatching time-based parameter`)
            }
            timeBasedParameter = new TimeBasedParameter(
                state.site,
                nodesets,
                state.timelineBarData?.workloadDataSource == WorkloadDataSource.READINGS ?
                    await getNodeReadings() :
                    null,
                state.timelineBarData?.environmentDataSource == EnvironmentDataSource.WEATHER ?
                    await getWeatherReadings(state.timelineBarData) :
                    null,
                state.timelineBarData?.environmentDataSource == EnvironmentDataSource.CLIMATE_QUICK,
                state.timePoint,
            )
            commit("setTimeBasedParameter", timeBasedParameter)
        }
        const timeBasedData = await timeBasedParameter.getTimeBasedData()
        commit("setTemporalData", { timeBasedData } as TemporalData)
        const climateWidgetData = await timeBasedParameter.getCurrentClimateWidgetData()
        commit("setClimateWidgetData", climateWidgetData)
        const simulationWidgetData = timeBasedParameter.getCurrentSimulationWidgetData()
        commit("setSimulationWidgetData", simulationWidgetData)
    },

    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async editSiteConfigurationScenario({ dispatch, state }, { id, dateFrom }: { id: string, dateFrom: string }) {
        const oldScenario = state.siteConfigurationsScenario.find(s => s.model.id == id)

        if (!oldScenario) {
            throw new Error("Cannot find old scenario")
        }

        const dateFromDate = new Date(dateFrom)
        // This one is undated
        const first = state.siteConfigurationsScenario[0]
        // Skip the first one
        const scenarios = state.siteConfigurationsScenario.slice(1).filter(s => s.model.id != id)
        const before = scenarios.filter(o => new Date(o.dateFrom) < dateFromDate)
        // First one (if different) is before
        if (first.model.id != id) {
            before.unshift(first)
        }
        const after = scenarios.filter(o => new Date(o.dateFrom) > dateFromDate)
        const unDated = scenarios.filter(o => o.dateFrom == "")
        let scenario: SiteConfigurationScenario

        if (after.length) {
            scenario = {
                model: oldScenario.model,
                dateFrom: dateFromDate.toISOString(),
                dateTo: after[0].dateFrom
            }
        } else {
            const endDate = new Date(dateFrom)
            endDate.setFullYear(endDate.getFullYear() + 10)

            scenario = {
                model: oldScenario.model,
                dateFrom: dateFromDate.toISOString(),
                dateTo: endDate.toISOString(),
            }
        }
        if (before.length) {
            before[before.length - 1] = {
                ...before[before.length - 1],
                dateTo: scenario.dateFrom,
            }
        }
        await dispatch("updateSiteConfigurationScenarios", [...before, scenario, ...after, ...unDated])
    },
    /**
     * This performs a simulation if possible. Errors will be bubbled here.
     *
     * @param param0
     */
    async performCurrentSimulation({ dispatch, state }) {
        if (state.nodes && state.timeBasedData) {
            await dispatch("simulate")
            dispatch("updateFilter", state.filter)
        }
    },
    /**
     *
     * @param actionState
     */
    async pickSiteConfigurationForTimePoint({ dispatch, state }) {
        if (state.siteConfigurationsScenario.length == 0) {
            await dispatch("selectSiteConfiguration", null)
        } else if (state.timePoint) {
            let useConfiguration = state.siteConfigurationsScenario[0]
            for (const configuration of state.siteConfigurationsScenario) {
                if (configuration.dateFrom && new Date(configuration.dateFrom) > state.timePoint) {
                    break
                }
                useConfiguration = configuration
            }
            if (useConfiguration.model.id !== state.siteConfiguration?.id) {
                await dispatch("selectSiteConfiguration", useConfiguration.model)
            }
        } else {
            const useConfiguration = state.siteConfigurationsScenario[state.siteConfigurationsScenario.length - 1]
            if (useConfiguration.model.id !== state.siteConfiguration?.id) {
                await dispatch("selectSiteConfiguration", useConfiguration.model)
            }
        }

        await dispatch("buildTimeBasedData")
        await dispatch("performHeadlessSimulation")
    },
    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async removeSiteConfigurationScenario({ dispatch, state }, id: string) {
        const scenarios = state.siteConfigurationsScenario.filter(s => s.model.id != id)
        await dispatch("updateSiteConfigurationScenarios", scenarios)
    },
    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async removeAllSiteConfigurationsScenario({ dispatch }) {
        await dispatch("updateSiteConfigurationScenarios", [])
    },

    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async selectSiteConfiguration({ commit, dispatch, getters },
        siteConfiguration: Models.SiteConfigurationModel | null
    ) {
        commit("setIsDraft", null)
        commit("setSiteConfiguration", siteConfiguration)
        await Preload.deviceModes(siteConfiguration).catch(e => console.warn(e))
        if (siteConfiguration) {
            commit("setIsDraft", !(await siteConfiguration.getFollows() ||
                await siteConfiguration.getStartsSite()))

            // Fetch static information
            await dispatch("loadNodesOnly")
            await dispatch("fetchNodeGroups")
            await dispatch("getNodeReadings", getters.sidePanelGraphGranularity)
            await dispatch("buildTimeBasedData")
            await dispatch("initQuickMode")

            // Run a simulation, but don't stop for errors
            await dispatch("performHeadlessSimulation")
            dispatch("updateFilter", { Node: [FilterType.COMBINED_CAPACITY] })
        }
    },

    /**
     * Perform a simulation with any errors going into headlessError. You can
     * await this, but it will not return failure.
     */
    async performHeadlessSimulation({ commit, dispatch }) {
        commit("setHeadlessError", null)
        try {
            await dispatch("performCurrentSimulation")
        } catch (e) {
            commit("setHeadlessError", e)
        }
    },

    /**
     *
     * @param actionState
     * @param mode
     */
    async setModeWithDefaults({ commit, dispatch, state }, mode: SimulationMode) {
        commit("setSelectedNode", null)
        commit("setNodeType", null)
        await dispatch("setMode", mode)
        if (mode == SimulationMode.QUICK && state.activeSiteConfiguration) {
            await dispatch('resetQuickModeProvisioning')
            await dispatch('onPressedResetToDefaults')

            commit("setSiteConfiguration", state.activeSiteConfiguration)
            await Preload.deviceModes(state.activeSiteConfiguration).catch(
                e => console.warn(e))
        } else if (mode == SimulationMode.ADV && state.activeSiteConfiguration) {
            commit("setSiteConfiguration", state.activeSiteConfiguration)
            await Preload.deviceModes(state.activeSiteConfiguration).catch(
                e => console.warn(e))
            const startDate = state.activeSiteConfiguration.getEffectiveDate()!
            const endDate = new Date(startDate)
            endDate.setFullYear(endDate.getFullYear() + 10)
            const siteConfigurationScenarios: SiteConfigurationScenario[] = [
                {
                    model: state.activeSiteConfiguration,
                    dateFrom: "2000-01-01",
                    dateTo: endDate.toISOString(),
                }
            ]
            await dispatch("updateSiteConfigurationScenarios", siteConfigurationScenarios)
        } else if (mode == SimulationMode.LIVE && state.siteConfigurations?.length) {
            if (state.activeSiteConfiguration) {
                commit("setSiteConfiguration", state.activeSiteConfiguration)
                await Preload.deviceModes(state.activeSiteConfiguration).catch(
                    e => console.warn(e))
            }

            const siteConfigurationScenarios: SiteConfigurationScenario[] = []
            let lastConfiguration: SiteConfigurationScenario | null = null
            let configuration = await state.site?.getFirstConfiguration()
            while (configuration) {
                const startDate = configuration.getEffectiveDate()!
                const endDate = new Date(startDate)
                endDate.setFullYear(endDate.getFullYear() + 10)
                const configurationInfo: SiteConfigurationScenario = {
                    model: configuration,
                    dateFrom: startDate,
                    dateTo: endDate.toISOString(),
                }
                if (lastConfiguration) {
                    lastConfiguration.dateTo = startDate
                }
                lastConfiguration = configurationInfo
                siteConfigurationScenarios.push(configurationInfo)
                configuration = await configuration.getFollowedBy()
            }
            await dispatch("updateSiteConfigurationScenarios", siteConfigurationScenarios)
        }
    },

    /**
     * @param actionState
     */
    async simulate({ commit, state }) {
        const timeBasedData = state.timeBasedData
        if (state.nodes && timeBasedData) {
            const nodesDataIn = await state.nodesDataFetcher.fetchCached(state.nodes)

            const baseCallHelper = new PointSimulationCallHelper(state.nodes,
                timeBasedData, state.timeBasedParameter)
            const callHelper = state.activePanel.mode == SimulationMode.QUICK ?
                new QuickModeHelper(state.nodes, state.quickModeProps,
                    baseCallHelper) : baseCallHelper

            let weather = await callHelper.getWeather()
            if (!weather) {
                if (!warnedDefaultWeather) {
                    warnedDefaultWeather = true
                    console.warn("No weather profile, using default")
                }
                weather = DefaultWeather
            }
            const itLoad = await callHelper.getItLoad()
            const provisioning = callHelper.getProvisioning()
            const nodesData = callHelper.getNodesData(nodesDataIn)

            const simulationData = await powerSimulatorService.simulate(
                nodesData,
                weather,
                itLoad,
                provisioning
            )

            commit("setTemporalData", { timeBasedData, simulationData } as TemporalData)
            const provisionedItLoad = await callHelper.getProvisionedItLoad()
            const provisionedSimulationData =
                await powerSimulatorService.simulate(nodesData, weather,
                provisionedItLoad, provisioning)
            commit("setTemporalData", { timeBasedData, simulationData, provisionedSimulationData } as TemporalData)
        }
    },
    /**
     *
     * @param actionState
     * @param incremental
     */
    async updateSimulationOverTime({ commit, dispatch, getters, state }, incremental = false) {
        if (!state.site || !state.timelineBarData || !state.nodes) {
            commit("setSimulationOverTime", null)
            return
        }
        const nodesets = await getters.nodesetsAsync

        if (!nodesets) {
            commit("setSimulationOverTime", null)
            return
        }

        const from = new Date(state.timelineBarData.from)
        const to = new Date(state.timelineBarData.to)

        if (getters.sidePanelGraphGranularity == Granularity.DAY) {
            // Workaround: Roll forward to next day to get the whole intuitive
            // period
            to.setDate(to.getDate() + 1)
        }
        // Clear SimulationOverTime in store
        commit("setSimulationOverTime", null)

        const timeSlices = DateHelper.getTimeSlices(from, to)
        commit("reloadingSimulationOverTime", timeSlices.length * 2)

        const getNodeReadings = async () => {
            if (!state.nodeReadings) {
                await dispatch("getNodeReadings")
            }
            return state.nodeReadings
        }
        const timelineBarData = state.timelineBarData
        const getWeatherReadings = async () => {
            if (!state.weatherReadings) {
                await dispatch("getWeatherReadings", {
                    from: new Date(timelineBarData.from),
                    to: new Date(timelineBarData.to),
                })
            }
            return state.weatherReadings
        }

        // This dictates how often we yield during request build. A value
        // calibrated to match once every 300ms for a big site is fine.
        const timeSlicesPerGroup = 24 * 2

        let activeTimeSliceGroup: Date[] = []
        const groupedTimeslices: Date[][] = [activeTimeSliceGroup]
        for (const slice of timeSlices) {
            if (activeTimeSliceGroup.length == timeSlicesPerGroup) {
                activeTimeSliceGroup = []
                groupedTimeslices.push(activeTimeSliceGroup)
            }
            activeTimeSliceGroup.push(slice)
        }

        let timeBasedParameter: TimeBasedParameter | null = null
        const results: SimulationOverTime = {}
        let progress = 0
        const promises: Promise<any>[] = []
        for (const timeSliceGroup of groupedTimeslices) {
            await new Promise(resolve => setTimeout(resolve, 0)) // Yield to the caller
            for (const slice of timeSliceGroup) {
                commit("setSimulationOverTimeProgress", ++progress)
                if (timeBasedParameter) {
                    timeBasedParameter.date = slice
                } else {
                    timeBasedParameter = new TimeBasedParameter(
                        state.site,
                        nodesets,
                        state.timelineBarData?.workloadDataSource == WorkloadDataSource.READINGS ?
                            await getNodeReadings() :
                            null,
                        state.timelineBarData?.environmentDataSource == EnvironmentDataSource.WEATHER ?
                            await getWeatherReadings() :
                            null,
                        state.timelineBarData?.environmentDataSource == EnvironmentDataSource.CLIMATE_QUICK,
                        slice,
                    )
                }
                const timeBasedData = await timeBasedParameter.getTimeBasedData()
                const promise = getSimResults(timeBasedData, timeBasedParameter, slice,
                    (slice, value) => {
                        results[slice.toISOString()] = value
                        commit("setSimulationOverTimeProgress", ++progress)
                        if (incremental) {
                            commit("setSimulationOverTime", { [slice.toISOString()]: value })
                        }
                    })
                if (promise) {
                    promises.push(promise)
                }
            }
        }
        await Promise.all(promises)
        commit("setSimulationOverTime", results)
    },
    /**
     *
     * @param actionState
     */
    async updateActualVersusExpected({ commit, dispatch, getters, state }) {
        const { simulationData, modelCapacities, timePoint } = state

        if (!state.site) {
            commit("setActualVersusExpected", null)
            return
        }
        const nodesets = await getters.nodesetsAsync

        if (!nodesets) {
            commit("setActualVersusExpected", null)
            return
        }

        const from = new Date(timePoint)
        from.setHours(0, 0, 0, 0)

        const to = new Date(from.valueOf())
        to.setDate(to.getDate() + 1)
        to.setHours(to.getHours() - 1)

        commit("setActualVersusExpected", null)

        const timeSlices = DateHelper.getTimeSlices(from, to)
        commit("reloadingActualVersusExpected", timeSlices.length * 2)

        const getNodeReadings = async () => {
            if (!state.nodeReadings) {
                await dispatch("getNodeReadings")
            }
            return state.nodeReadings
        }
        const getWeatherReadings = async () => {
            if (!state.weatherReadings) {
                await dispatch("getWeatherReadings", {
                    from,
                    to,
                })
            }
            return state.weatherReadings
        }

        // This dictates how often we yield during request build. A value
        // calibrated to match once every 300ms for a big site is fine.
        const timeSlicesPerGroup = 24 * 2

        let activeTimeSliceGroup: Date[] = []
        const groupedTimeslices: Date[][] = [activeTimeSliceGroup]
        for (const slice of timeSlices) {
            if (activeTimeSliceGroup.length == timeSlicesPerGroup) {
                activeTimeSliceGroup = []
                groupedTimeslices.push(activeTimeSliceGroup)
            }
            activeTimeSliceGroup.push(slice)
        }

        let timeBasedParameter: TimeBasedParameter | null = null
        const results: SimulationOverTime = {}
        let progress = 0
        const promises: Promise<any>[] = []
        const allTimeBasedData = {}
        for (const timeSliceGroup of groupedTimeslices) {
            await new Promise(resolve => setTimeout(resolve, 0)) // Yield to the caller
            for (const slice of timeSliceGroup) {
                ++progress
                commit("setActualVersusExpectedProgress", progress)
                if (timeBasedParameter) {
                    timeBasedParameter.date = slice
                } else {
                    timeBasedParameter = new TimeBasedParameter(
                        state.site,
                        nodesets,
                        state.timelineBarData?.workloadDataSource == WorkloadDataSource.READINGS ?
                            await getNodeReadings() :
                            null,
                        state.timelineBarData?.environmentDataSource == EnvironmentDataSource.WEATHER ?
                            await getWeatherReadings() :
                            null,
                        state.timelineBarData?.environmentDataSource == EnvironmentDataSource.CLIMATE_QUICK,
                        slice,
                    )
                }
                const timeBasedData = await timeBasedParameter.getTimeBasedData()
                for (const [key, value] of Object.entries(timeBasedData)) {
                    allTimeBasedData[key] = { ...allTimeBasedData[key], ...value }
                }
                const promise = getSimResults(timeBasedData, timeBasedParameter, slice,
                    (slice, value) => {
                        results[slice.toISOString()] = value
                        ++progress
                        commit("setActualVersusExpectedProgress", progress)
                    })
                if (promise) {
                    promises.push(promise)
                }
            }
        }
        await Promise.all(promises)

        const powerDemandData = PowerDemandHelper.extractPowerDemandData(results)
        const powerDemandAverages = PowerDemandHelper.calculatePowerDemandAverages(powerDemandData)

        const nodes = nodesets.map(nodeset => nodeset.nodes).reduce((accumulator, currentValue) => [...accumulator, ...currentValue], [])
        const precalculatedData = await FiltersCalculationHelper.actualExpected(
            nodes,
            simulationData!,
            allTimeBasedData as TimeBasedData,
            await getNodeReadings() ?? {},
            modelCapacities!,
            timePoint
        )

        for (const [nodeReference, powerDemandAverage] of Object.entries(powerDemandAverages)) {
            (precalculatedData[nodeReference] as { expected }).expected = powerDemandAverage
        }

        commit("setActualVersusExpected", precalculatedData)
    },
    /**
     * @param actionState
     * @param incremental
     */
    async ensureSimulationOverTime({ dispatch, state }, incremental = false) {
        if (!state.simulationOverTime) {
            await dispatch("updateSimulationOverTime", incremental)
        }
    },
    /**
     * @param actionState
     */
    async ensureActualVersusExpected({ dispatch, state }) {
        if (!state.actualVersusExpected) {
            await dispatch("updateActualVersusExpected")
        }
    },
    /**
     * @param actionState
     * @param scenarios
     */
    async updateSiteConfigurationScenarios({ commit, dispatch }, scenarios: SiteConfigurationScenario[]) {
        commit("setSiteConfigurationScenarios", scenarios)
        await dispatch("pickSiteConfigurationForTimePoint")
    },
    /**
     *
     * @param actionState
     * @param timePoint
     */
    async updateTimePoint({ commit, dispatch }, timePoint: Date) {
        commit("setTimePoint", timePoint)
        await dispatch("pickSiteConfigurationForTimePoint")
    },
    /**
     *
     * @param actionState
     * @param calibrationBound
     */
    updateCalibrationBound({ commit }, calibrationBound: number) {
        commit("setCalibrationBound", calibrationBound)
    },
    /**
     *
     * @param actionState
     * @param customPeriod
     */
    async rebuildGraphInfo({ commit, state }) {
        if (state.siteConfiguration) {
            const siteParameter = new SiteParameter(
                state.site,
                state.siteConfiguration,
                state.graphCustomPeriod ||
                { startDate: new Date(), endDate: new Date() }
            )

            const siteInfo = await siteParameter.siteInfoBuilder()
            const meteredData = await siteParameter.getMeteredData()

            commit("setGraphInfo", { siteInfo, meteredData } as GraphInfo)
        }
    },
    /**
     *
     * @param actionState
     * @param text
     */
    setSiteConfigurationSearchText({ commit }, text: string | null) {
        commit("setSiteConfigurationSearchText", text)
    },
    /**
     *
     * @param actionState
     * @param timelineBarData
     */
    async updateTimelineBarData({ commit, dispatch, state },
        timelineBarData: TimelineBarData
    ) {
        commit("setTimelineBarData", timelineBarData)
        dispatch("clearTimeBasedParameter")
        if (state.site && state.nodes) {
            await dispatch("buildTimeBasedData")
            await dispatch("performHeadlessSimulation")
        }
    },
    /**
     * @param actionState
     */
    onPressedResetToDefaults({ commit, dispatch }) {
        commit("setQuickModeItLoad", null)
        commit("setQuickModeWeather", null)
        return dispatch("initQuickMode")
    },
    /**
     *
     * @param actionState
     * @param itLoad
     */
    async updateQuickModeItLoad({ commit, dispatch },
        itLoad: { [key: string]: number } | null
    ) {
        commit("setQuickModeItLoad", itLoad)
        await dispatch("performCurrentQuickSimulation")
    },
    /**
     *
     * @param actionState
     * @param weather
     */
    async updateQuickModeWeather({ commit, dispatch },
        weather: Temperature & { relativeHumidity?: number } | null
    ) {
        commit("setQuickModeWeather", weather)
        await dispatch("performCurrentQuickSimulation")
    },
    /**
     *
     * @param actionState
     * @param values
     */
    updateQuickModeSliderValues({ commit }, values: QuickModeSliderValues) {
        commit("setQuickModeSliderValues", values)
    },
    /**
     *
     * @param actionState
     * @param customPeriod
     */
    updateGraphCustomPeriod({ commit, dispatch },
        customPeriod: State["graphCustomPeriod"]
    ) {
        commit("setGraphCustomPeriod", customPeriod)
        return dispatch("rebuildGraphInfo")
    },
    /**
     *
     * @param actionState
     * @param node
     */
    async selectNode({ commit, getters }, node: AnyNode | null) {
        let nodeReference: string | null
        let nodeType: SimulationNodeType | null
        if (node) {
            nodeReference = node.type + "/" + node.id
            nodeType = await SimulationNodeTypeHelper.simulationNodeTypeFor(node, getters.connections)
        } else {
            nodeReference = null
            nodeType = null
        }
        commit("setSelectedNode", nodeReference)
        commit("setSelectedNodeObject", node)
        if (nodeType) {
            commit("setImpliedNav")
        }
        commit("setNodeType", nodeType)
    },
}

const SimulationViewModule = {
    namespaced: true,
    state: {
        ...new State(),
    },
    getters: {
        ...SiteViewStateGetters,
        ...SimulationViewStateGetters,
        ...Getters
    },
    mutations: {
        ...SiteViewStateMutations,
        ...SimulationViewStateMutations,
        ...Mutations
    },
    actions: {
        ...SiteViewStateActions,
        ...SimulationViewStateActions,
        ...Actions
    },
}

export default SimulationViewModule